
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexc6omkPQqE56bb8gThvq2PFlqiA15jv5j0NOhpU9bxb4Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginIEIwCOneABWSlQBkkhNVQi9_RPwIQgTmfa0QHaMa3_45IMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyH1xaKOSRB65oqCRosW3BCT2suzzOwc3M0lC_NbCwuHwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemap52ZUN1AOZbCf4eEOj8vY9P2Q9JO_45x_45VciP2E_45x9xEQUMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45us1fCDtRjaAKMcd1183Wgixc57tn7xH0N7F9VkH4W5zbkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerhmgvug_fAhUcnl6xoeYGUIAwvtU2Qykx9gweDssxzu0Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaints6HTdi7eZAlUWQ41_4518X87JSgoO6_lSUZywKSMV5iGdIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us9MCB3IAqrW9LbtdxIK7DawKN4Ox07EhHulmwtOrJwz8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexz4atTD1J3CCw7UdGqfks6BM23ci2eB_ywQ5AxYaQr0EMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexvOsaxS7nENMUjCDkZbglMsfQkf0oS_PPNcZLmWc6v6EMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93yOLYB9eBw6cu2Gz0jjCJzRSQvF7oza4PpiuNxt6cFgwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsG91R6_L_45l09WyHmy9Iev6QBLwV9XLzQDfHhwEw0ybdYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useViaVJp69QUWcHd1YuWT904_Y83WqtIhKfZgO9BOx42kMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexXeW_vqRMpzqsEbWV_d2F9kpBTQGNBYG_EfVAb6Dg2pgMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policymKb4oD5YR3DMiHf6ujAn6NOV6BZZz26YnHQs_45FHEMlYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordwBqZ3lglPXTenWoFXAEEeaeN_TrgONg2GNSSMozf2u8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as index_45_EvARk_45Vi0PGUrUrldKYAoY9SQbPxNCu_NkJb5fXz8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexz4SZNwz7rcYcoQjGMcTMjRuQoZAS4irMAOGJjXpYuMYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordKlWNizVyp6q7z5UZCbjfaK_15S6D1v_P8frU5_45xbWDkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errord430pAdy_CAnIvXNgEBA46HtO5aRirh0JLmzv55XL_45AMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexKOM8P9BZTMgYiwBPQ4qvuxFgvgMO7n5t4T_seP9Klt0Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_935Bge8mN3NVot9c_45dbpldZJgMzT7wUldf75s76HQoHZwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesp9g1sDJxGjwkEwyGuQrexytUy_45pU9bmK7A5w2LMIJkAMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeh3mWTlNvNbrT0_WEh_iA35hzBRn9_pkxvgTOGpoKfuQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexQIJsUffnscatsyVoPVFZIBLdAnYT3tGJ13jgZlL6MyEMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordBMq1AnlJRYVmntoUDNyPFeBJvcuHW5O4dSluUS5GXSAMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backP4PFtwoRjL59x_45Vwgv7L7yxC7U3QOMvF_pQiTSzO_45QsMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergeT0ngX3pVwwY7_UwC3Qwb2L1BB3YxW_uLtSduSa7yJGQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexIqaQ_45IHi0rSDsO5ttot9tGjZQVUBMcqwFlvLDZSHTUAMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93a3bzK4lxLgAViDDaKM0FfU0hDN6mPnQLJSnuXr_mGjkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youytpU5MNemb67CF_T_45juByJUnMpKjs07Rw4_2w7eV3ZcMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45yougjVQgeCQoCncz97itBCksVtpaZKGvGxz4_45ZeumaDB20Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexqcHB_vwomBY4fQIq8tlrkNhoGTuDG_45wVOLmmbCgrEaIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexyLHO4nflqOIJK5WmHgpC08imdTDyWMTm1dmiPncRdAoMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93Ks0DtBKY2DVYIEpmJ6Iiujixs_45hcvPDxZmfB8VlfPOkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexM2L1DDRfl8yypl2HTl1VvOcP_My0lklpZFFsoDhIUrwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexUu8G1NNN859eL3JTFs9qcZd1VANbrjV3Cvk6hlmTcBEMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93Nfh_zv1O2wg1r6IaZNsh6lAg0LybuYO_45vdAI2piayBMMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackTlbcerc3RaiR9pF5SyJJo_2HetxyeBvqF1IHF_3n8gYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimWouvhZrDfSmhmU0hak2dYBboYXq9a4nlj22TH4gi82YMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexZS0AW0ygiUwy63fh9nOMC8Tc7uHFsUXBRjAW96l5icUMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youXshyBs5htiZaX6A1gDNp_BZc3D9wl2mOiXqLjkIvYYcMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorwxxrfzyD07_LX2GbWHgiishPpLzRYVYgnWF5q_5QQsgMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indextuDV_wfsbtl2JrRZN7JFcOxTYBtdYVlSXR1QykCqjssMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexxpDaHZ3yRCIC7adjxp_45WSqKopbTIBgRZ9Bb3v8gZTvkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorbjM5lKmsq32LarbgYVNMmNaM9wJdFUBlCIQ_45UWNBHIIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_937NywqKmKRUCL0hNGFHHC0kr2usPOouptXxEY6G5EgAAMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsRDEVHKpm_45B5Jg2ZTq8yhhIPdNIy_JPUxv_45cFdfEpchcMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45rates9xbNmIf2GvimZOIrvLbQIYD7mlbZ_CzoB1Qs9UC3Zj8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93JytK_yfBf3XKtsfNofbbG0mbhhfl5YlY49ntOAwYrvQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexTFKeQ0QPNRd5a45nV5a2XUuujrAQszTR1Y9SLtQFu44Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsx1tORGXk6MCu_45kL9KaL4c1HMlJLVAYJq2AFDEl97uGYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexh_45kvJH_45sh8tBRcSEweYAi0REc4f0MHzwuO_458lEWfGoIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatzX5WHiLObsjUwwpNz0VVMpoWevukXnhuLKRTkjaRYFgMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleucF6ozBlAr6deWi1_xZWA34lbGhLP5ITsBITqaMuTRQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youGYTZQK6PYIG3qiRJPr7C0ysigHAwenxBzP3BAAYaOT4Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveqxx5Bhfm2QqvRQm8jg3nA7eYEWZIpRk2MJEvDQB4XB8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_935jaYrsTvhAwwJS8DVlOZcobykzCyYiesJNXc58TukvgMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addGGcuLc635tmMn_45tUswDgog_45YjL_45sIg9SlHpzsYfYQMIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexkkVgrFUEhlPr_iqeXr52SByrdxHcHsKAbW4szJAplSYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93PbP5ZSOwinKqNp7jegJ2RFnUjQyQyjn8yFHs56bG_45_45oMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbacktx0eWD0mI3mZtV2uokQUPUQbXylaqP5MDePPa8XB9mAMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93y_459fsS1iIJktY0JNHFMM9pfW2hda1RXqmSpXU6aNafYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youa9wEk4j487c8DTqSZIDQpxK8_45VSPiufYtj4_45_45JXqaM8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bcdoZhV5n8e_CAaLw142JPLk0VtTV9_45Mw68SFrYyUPP0Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexYCruGbJe3NZr10PEbXLEWtcpD0iKgw4npwQ2uiZj6SwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93XC_WZTe6Ks7fOjYV2yYzTwkKlp_k2g00s4kGjp3N90sMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93lFMdi2a8REVVHZ8sQsu6gugD1I_45T4FFZJ0_45HaV_45nZIwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexU3h2jhXxqAKwHxP9pRcF9IXpKXsajGdp7AlAEu21wcYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addqM5C2lWZtGwB64y1_45boBDlod_45No2xwYGQaH_45k78Dh_45sMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93IGTgQSf4QVyxwJ8o7dNUcc_45gKYWwRigfefNSZmdpWlQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsX_bW4I1SCtIab8NMHv_slT0b5_45CKVDa784Ti1yO5XxIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackGPS_45hY6LXHAGu04iRVZs7cyVMEmr9VcJzMiDrpHx4KsMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eicVXon4Cxw7sUbVh8ILdK6diAcJt2aEho47rhluKAY6cEMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youzIpxFkwAZ4cpJUcCCiq_45i221Bmv2byPWuqNRGZE7HEgMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93yXZBzYVZIdxSFejF9YdWQ0TDA57rW67dPfL59x8GKlQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93v_457YiGeyE_1puPSC6Zp2gnLrELTDbTs7x1oXguHzFqoMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonUW_45msGbNQmhsAB9SME455zv_45Qr0hMWlWd7M8ORkylFkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step140brvtSVuUyI0_rSVEHVXBtXcHGpEKvZIQ6qYEwj8bcMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as detailsk2kboe_45tNpdPGBQiypySpoTFOZKub19qxx4ptHpNX40Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsRakDCgQn7E_45iWTAhUctnDeIdnYDYwb5X_4567ywXaP_45LYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexImOkeAPgpm9xdRVW_vUUYhN5gGan_45FQp8SLmNbLLBP4Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signup2y_45VpS55kHQPGvzXZi_CPzrvqZN25LwWobIvKWNXE4UMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youpPNPgoSfuXctZezVrpwGlrg6o4Bn5pDM4yVBPDz5DIIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93sWblTUzjO1QcPuiL1RUhLNn4IzEE1zVinR6jgTZjySQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93UZ4ACyH0HbsFSjW58lKQiapZyKhSmM0JO_45fOIU95UKIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0WMxFU_45LgT5TuFDygFkUvikURNxhFllaMg_45c5MWCl7D8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersWPNysKsNN91jP5H2T_dTqLGCIOaYgICRCM4CMtyJm54Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youTEvnFBPGMGtEbnNCf3ov4Z_KeGx3l0UVf6_450GZBypegMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as details9ey4id_45XBX9nQozrxwbVMHOwytvOkuPp0zoacjzU1pwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsgH_eK4V38Xnbu0FWpHniUyQ3Eb32kQqn9cuF0suwsHcMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93Cwi70JDmvHs2H7Qdm54uxY1aZu9MmnfSe8xu8fCWw7wMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate5V_45tWiyjPSAvapoYQr0_45OnGZcUq21xW83UpYx_FDYs4Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93y09o1XuOv0AStRKStDovj1rQ7Xx9kcGChAUrXjsaFqMMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsCFeUebsKvN_cCxOq8jSqkm75rqBaLhXITYIpGm6YeDEMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step07LbtKgxY1XOctSTYpdpRRNhzHZOH6f0R8jlqCGVt3gIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexZsL4TiWTGCZX6nYeKDuleWFlI0JAYHQ2MApyCdSUk6IMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexHRX5FFSD9Y0bJiOCbM6l2Kmn7faTc3iZeduldZtld_45kMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsd5ppD9WjR8Q5GL_45AfCVoS2pTO3SRIOeS5HPvZz3RJh8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionswe8nKlimFe3t4Y3RwX2MhNNAEYn3PWlqVOs2GV_45n_45fgMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93w7Qy3l9H2BASSgaLzXEsKIiF0ZtOJlynEvF9lhEobawMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as indexQcHvNiWR26FkR_458AMNnLipfD8dCtkOj64UdCDVwHtoIMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsC0ine0jF4fsXPK592xP4NWldIDopLzrPe1mR0KJS2R8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexjgMX4c_e7NesrjoCzAK4IjQjFFdM9tfr2ocesCsNhMgMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as success70PZWz_45A9CquhlzJfQG6b_GAKXVafhKk15dAhiQBpsAMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerCuoVrnb_SDYGJZVaFkL5aZ9xGP8_45G50GBKVdUI6sCVkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsBz4zGRCJiwjzncYIafjKTf2bcXLSmX1llU42KClggrsMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourwJ9fopQpl1GLR2aOZDrnBru8Im_d5_45cItDBBCQ6u0CkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93JfNDW_45fR1lf6hbLqweGW3MvTfBtkdmhfkrpi1hj8_j0Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexrVk8YoOLphCspGpOpzxspO3idGdX9xou3ysGdcxHMDkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93sluOSQc_UB_452FnFv5NXL1LVMHsIowVEDpNkxyxhkJZwMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexIWdJPOXFCeXUQ6Z50gNCPIVyQY2qMQsFRPMoLr2gn3cMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93cZg5FxyuA4tQmMCDIVFunk_45vPklNjypzH6_N_45fvJ00QMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45youKIuZjpT7nY7goFBHzbXsKQbh2UH8gptdfQnlmIH68JoMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexPI1soz6zzGEM5kXp_6r_451K9PYOLObfgQLSc4TtPvnQoMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexErnUOx_WTp5_45YaOCvsMZW2HqO_45Xn7l248Z6F0iOP_YcMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsKDEt8xI2v_7fQVzW_m70c5NORxQ3wJGXX4TmG8OrqlYMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expired2cns68V__sioM7mx_a8Av15w2HpigIJBUeMYd_452FoI0Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexfpYh2cDqLc26c_QsMfBpScxRc6mOH5_45OHZCjvSUetqAMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youxX7KcRB3tLvNnH30UnFOuYotbnhAXPU5cYPVjdaKr00Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsrNspNutsgWytTSY5sudiL00_45lYZBPzN_45ZTQ6Q0ss9XsMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentWXktCAlr0D9JmPRb_J_45YKOQPqcjnITEyuXB9XEKPIYoMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmation9dleXW5kehsqxIH3NtFAuwG_SN1kGXYndmUiZvmH0xQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionbQsjJRMqtgTgrSNKAPOM_45T9hUejhNS_NZyZvFgs4B_YMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsz6X_457WYvIlXDhP3L6og18ZRUrrjJQfPGdiXx4QUkbzsMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmation1iAa_AgWRiPwbC0G2c25mKAjgUpFpnaiIkynSu_45glNQMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93tAbN7y05oX2Gws4GllkTQE6jl46cCwChuyAYyGiffI8Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93XbZgLCyIGoGcagPaK3JGD43yp8LOFhBFNdrsnroHaVMMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93NS_x8UCMK7XvYKbr2PqlcH_45ztwG_45l1vJK6KwLb3VfjoMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93mB6GKPEKUurI_mdfaiWLCAoUmDIcyTr78JsKCyE11p0Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexq9qDhqqTuqFOjgGc305_45qQ8Bg2_8U1NNDysvjyWKDvUMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45details0Z0ezoF8rNKtf3zghExx8QNBwP_45TMUGoZlmyBs3nvQMMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93FyseRQlaqSNrrRhOuCy9ASVRmAwg8TiQ7ljm_hEXAvoMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93WcsfLQccecuVjaVdXoXFGJtTCt87QMAvqd0fBcqu7YkMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as index0_5EgUp9Tqw7xLUwjhshJqsreNDVruqhdo3K0s2JM1QMeta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as index8fsfiz0DQhhc_45tcBYqsxnvJip_457nM4wvU42GH8FxkR0Meta } from "/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginIEIwCOneABWSlQBkkhNVQi9_RPwIQgTmfa0QHaMa3_45IMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerhmgvug_fAhUcnl6xoeYGUIAwvtU2Qykx9gweDssxzu0Meta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordwBqZ3lglPXTenWoFXAEEeaeN_TrgONg2GNSSMozf2u8Meta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexz4SZNwz7rcYcoQjGMcTMjRuQoZAS4irMAOGJjXpYuMYMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordKlWNizVyp6q7z5UZCbjfaK_15S6D1v_P8frU5_45xbWDkMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordBMq1AnlJRYVmntoUDNyPFeBJvcuHW5O4dSluUS5GXSAMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93a3bzK4lxLgAViDDaKM0FfU0hDN6mPnQLJSnuXr_mGjkMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexM2L1DDRfl8yypl2HTl1VvOcP_My0lklpZFFsoDhIUrwMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexZS0AW0ygiUwy63fh9nOMC8Tc7uHFsUXBRjAW96l5icUMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexxpDaHZ3yRCIC7adjxp_45WSqKopbTIBgRZ9Bb3v8gZTvkMeta?.props ?? {"verificationNeeded":true},
    meta: indexxpDaHZ3yRCIC7adjxp_45WSqKopbTIBgRZ9Bb3v8gZTvkMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsx1tORGXk6MCu_45kL9KaL4c1HMlJLVAYJq2AFDEl97uGYMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexh_45kvJH_45sh8tBRcSEweYAi0REc4f0MHzwuO_458lEWfGoIMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbacktx0eWD0mI3mZtV2uokQUPUQbXylaqP5MDePPa8XB9mAMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate5V_45tWiyjPSAvapoYQr0_45OnGZcUq21xW83UpYx_FDYs4Meta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsd5ppD9WjR8Q5GL_45AfCVoS2pTO3SRIOeS5HPvZz3RJh8Meta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerCuoVrnb_SDYGJZVaFkL5aZ9xGP8_45G50GBKVdUI6sCVkMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsBz4zGRCJiwjzncYIafjKTf2bcXLSmX1llU42KClggrsMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93JfNDW_45fR1lf6hbLqweGW3MvTfBtkdmhfkrpi1hj8_j0Meta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionbQsjJRMqtgTgrSNKAPOM_45T9hUejhNS_NZyZvFgs4B_YMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93tAbN7y05oX2Gws4GllkTQE6jl46cCwChuyAYyGiffI8Meta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93XbZgLCyIGoGcagPaK3JGD43yp8LOFhBFNdrsnroHaVMMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93mB6GKPEKUurI_mdfaiWLCAoUmDIcyTr78JsKCyE11p0Meta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45details0Z0ezoF8rNKtf3zghExx8QNBwP_45TMUGoZlmyBs3nvQMMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93FyseRQlaqSNrrRhOuCy9ASVRmAwg8TiQ7ljm_hEXAvoMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93WcsfLQccecuVjaVdXoXFGJtTCt87QMAvqd0fBcqu7YkMeta || {},
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src312178763/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]